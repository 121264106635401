import React from 'react'
import './Button.css'

const SIZES = ['btn--medium', 'btn--large']

export const Button = ({children, type, onclick, buttonSize}) => {
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return(
        <button className={`button ${checkButtonSize}`} onclick={onclick} type={type}>
            {children}
        </button>
    )
}
