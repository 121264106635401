import React from 'react'
import '../App.css'
import { Button } from './Button'
import './HeroSection.css'

function HeroSection() {
  return (
    <>
      <div className='hero-container'>
        <img className='hero-background-1' src='./images/background-hero.jpg' />
        <h1><b>Consultoria Trading Em Foco</b></h1>
        <div className='first-description'>
          <p>
            Nessa consultoria personalizada e <b>INDIVIDUALIZADA</b>, irei te pegar pela mão e te ensinar na prática o operacional que uso no meu dia a dia para conseguir meus resultados consistentes.
            Você terá encontros individuais online comigo, onde te mostrarei como lucro no trading de forma <b>SEGURA E PREVISÍVEL</b>.
          </p>
        </div>
        <div className='what-is-lists'>
          <div className='description-list-left'>
            <h3>
              <span className='white-text'>O que</span><span className='red-text'><b> NÃO É </b></span><span className='white-text'>a consultoria:</span>
            </h3>
            <ul className='what-is-not-description-list'>
              <li>Curso online de video-aulas gravadas sem contato direto com o mentor.</li>
              <li>Mentoria em grupo, sem acompanhamento individual de performance.</li>
              <li>Ensinamentos superficiais, com pouco aprofundamento em conceitos técnicos.</li>
              <li>Apenas para quem já é experiente com o mercado financeiro.</li>
            </ul>
          </div>
          <div className='description-list-right'>
            <h3>
              <span className='white-text'>O que</span><span className='green-text'><b> É </b></span><span className='white-text'>na prática:</span>
            </h3>
            <ul className='what-is-description-list'>
              <li>7 HORAS de encontros individuais, "mano-a-mano", comigo em horários flexíveis.</li>
              <li>Acesso VITALÍCIO aos meus indicadores e regras de coloração exclusivos.</li>
              <li>Acesso direto pessoal para tirar dúvidas e ter acompanhamento INDIVIDUAL.</li>
              <li>Para QUALQUER UM, independente da experiência com o mercado.</li>
            </ul>
          </div>
        </div>
        <div className='second-description'>
          <p>
            Além disso, você terá o <b>BÔNUS</b> de mais 60 dias do meu acompanhamento de performance de forma <b>GRATUITA</b>, onde estarei à sua disposição para tirar dúvidas e para que eu também possa direcioná-lo da melhor forma possível.
          </p>
        </div>
        <div className='hero-btns'>
          <a href='https://pay.greenn.com.br/36852' target="_blank">
            <Button className='button' buttonSize='btn--large' >
              QUERO RECEBER MINHA CONSULTORIA EXCLUSIVA
            </Button>
          </a>
        </div>
      </div>
      <div className='history-container'>
        <div className='history-description'>
            <p>
              Durante todo o ano de 2023, eu obtive ganhos constantes diários aplicando todos os conceitos que vou te apresentar nesta consultoria.
              Eu compartilhei diariamente nas redes sociais as minhas operações e a mentalidade de sucesso por trás de cada uma.
            </p>
        </div>
        <img className='history-patrimony-graph' src='./images/patrimony-graph.png' alt="" />
      </div>
      <div className='examples-container'>
        <img className='background-examples' src='./images/background-examples.jpg' />
        <div className='examples-description'>
          <p>
            Porém, apenas os alunos da Consultoria Trading em Foco tiveram acesso especial a cada detalhe dos trades e conseguiram replicá-los nos seus operacionais. Confira alguns resultados dos alunos:
          </p>
        </div>
        <div className='examples-items-list-1'>
          <img className='examples-item-1' src='./images/example-1.png' />
          <img className='examples-item-2' src='./images/example-4.png' />
          <img className='examples-item-3' src='./images/example-5.png' />
        </div>
        <div className='examples-items-list-2'>
          <img className='examples-item-4' src='./images/example-6.png' />
          <img className='examples-item-5' src='./images/example-8.png' />
        </div>
      </div>
      <div className='page-end'>
        <div className='page-end-description'>
          <p>
            Clique abaixo e seja guiado por mim para ter resultados mais consistentes no mercado financeiro. Faça parte desse grupo seleto de dezenas de pessoas que já aplicam meu operacional nos seus trades.
          </p>
        </div>
        <div className='hero-btns'>
          <a href='https://pay.greenn.com.br/36852' target="_blank">
            <Button className='button' buttonSize='btn--large' >
              TAMBÉM QUERO FAZER PARTE DA CONSULTORIA
            </Button>
          </a>
        </div>
      </div>
    </>
  )
}

export default HeroSection